html, body, #root {
  width: 100%;
  height: 100%;
}
* {
  box-sizing: border-box;
}
*::-webkit-scrollbar {
  display: block;
  width: 2px;
  height: 2px;
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}